import https from "./https";
import store from "@/store";

const organization = store.state.liffGeneral.orgCode;

const url = {
  getUrl(urlCode) {
    return https.get(`${organization}/liff/url/${urlCode}`);
  },
};

export default url;
